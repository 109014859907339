import { Select, SelectProps } from "antd";
import {
  useAccountingServiceGetAccounts,
  useAccountingServiceGetAccountsKey,
} from "api/queries";
import { IAccountItem } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface IAccountSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: IAccountItem[];
  selectProps?: Partial<SelectProps>;
}

const AccountSelectWithForm: React.FC<IAccountSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetAccounts(
      {
        filters,
        pagination: "",
        sort: "",
      },
      [useAccountingServiceGetAccountsKey]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            accountName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select an account"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.accountName,
            value: _v.idAccount,
            key: _v.idAccount + "account-id-select-account",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const AccountSelectNoForm: React.FC<IAccountSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetAccounts(
      {
        filters,
        pagination: "",
        sort: "",
      },
      [useAccountingServiceGetAccountsKey]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            accountName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select an account"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.accountName,
            value: _v.idAccount,
            key: _v.idAccount + "account-id-select-account",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

export default function AccountSelect(props: IAccountSelectProps) {
  if (props.isForm) {
    return <AccountSelectWithForm {...props} />;
  }
  return <AccountSelectNoForm {...props} />;
}
