import React from 'react';
import { Drawer, Button, Table, Typography, Divider, Row, Col, Space } from 'antd';
import dayjs from 'dayjs';
import { IGetInvoiceDetailsResponse } from 'api/requests';

const { Title, Text } = Typography;

export interface IInvoicePriceDrawerProps {
  open: boolean;
  onClose: () => void;
  data: IGetInvoiceDetailsResponse;
}

function InvoicePriceDrawer({ open, onClose, data }: IInvoicePriceDrawerProps) {
  const {
    invoiceNumber,
    billingAddress,
    notes,
    invoiceStatus,
    invoiceDate,
    invoiceDueDate,
    invoiceSubtotal,
    invoiceDiscountTotal,
    taxAmount,
    invoiceTotalAmount,
    account,
    customer,
    tax,
    invoiceItems,
    invoicePayments,
    totalPaidAmount,
    discountScheme,
    totalDiscountAmount,
  } = data;

  // Columns for invoice items table
  const itemColumns = [
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: number) => <Text>{text}</Text>,
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (price: number) => <Text>{price?.toFixed(2)}</Text>,
    },
    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (total: number) => <Text>{total?.toFixed(2)}</Text>,
    },
  ];

  // Columns for payments table
  const paymentColumns = [
    {
      title: 'Payment Account',
      dataIndex: 'paymentAccount',
      key: 'paymentAccount',
      render: (account: string) => <Text>{account}</Text>,
    },
    {
      title: 'Payment Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      render: (amount: number) => <Text>{amount?.toFixed(2)}</Text>,
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (date: string) => <Text>{dayjs(date).format('YYYY-MM-DD')}</Text>,
    },
  ];

  // Handle print function
  const handlePrint = () => {
    window.print();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Invoice Details"
      placement="bottom"
      height="100%"
    >
      <div className='printable-container'>
        <Row justify="space-between">
          <Col>
            <Title level={4}>Invoice #{invoiceNumber}</Title>
            <Text>Status: {invoiceStatus}</Text>
          </Col>
          <Col>
            <Button type="primary" onClick={handlePrint}>
              Print Invoice
            </Button>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={12}>
            <Title level={5}>Customer Details</Title>
            <Text strong>Name: </Text>
            <Text>{customer?.customerName}</Text>
            <br />
            <Text strong>Email: </Text>
            <Text>{customer?.customerEmail}</Text>
            <br />
            <Text strong>Phone: </Text>
            <Text>{customer?.customerPhone}</Text>
            <br />
            <Text strong>Billing Address: </Text>
            <Text>{billingAddress}</Text>
          </Col>

          <Col span={12}>
            <Title level={5}>Invoice Details</Title>
            <Text strong>Issue Date: </Text>
            <Text>{dayjs(invoiceDate).format('YYYY-MM-DD')}</Text>
            <br />
            <Text strong>Due Date: </Text>
            <Text>{dayjs(invoiceDueDate).format('YYYY-MM-DD')}</Text>
            <br />
            <Text strong>Account: </Text>
            <Text>{account?.accountName}</Text>
          </Col>
        </Row>

        <Divider />

        <Title level={5}>Items</Title>
        <Table
          columns={itemColumns}
          dataSource={invoiceItems}
          pagination={false}
          rowKey={(item) => item.idInvoiceItem}
          summary={() => (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total:</Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={3}>
                  <Text>{invoiceSubtotal?.toFixed(2)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Discount ({discountScheme}):</Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={3}>
                  <Text>-{totalDiscountAmount?.toFixed(2)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Tax:</Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={3}>
                  <Text>{taxAmount?.toFixed(2)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Grand Total:</Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={3}>
                  <Text>{invoiceTotalAmount?.toFixed(2)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )}
        />

        <Divider />

        <Title level={5}>Payments</Title>
        <Table
          columns={paymentColumns}
          dataSource={invoicePayments}
          pagination={false}
          rowKey={(payment) => payment.paymentId}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total Paid:</Table.Summary.Cell>
              <Table.Summary.Cell index={0} colSpan={2}>
                <Text>{totalPaidAmount}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />

        <Divider />

        <Row>
          <Col>
            <Title level={5}>Notes</Title>
            <Text>{notes || 'No additional notes.'}</Text>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
}

export default InvoicePriceDrawer;