import { Col, Row } from "antd";
import Table from "antd/es/table";
import { IGetInvoiceDetailsResponse } from "api/requests";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

export interface IInvoicePaymentsSegment {
  data: IGetInvoiceDetailsResponse | undefined;
  loading: boolean;
}
function InvoicePaymentsSegment({ data, loading }: IInvoicePaymentsSegment) {
  const company = useSelector((state: any) => state.company);

  const columns: any[] = [
    {
      title: "Payment ID",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    {
      title: "Payment Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      render: (amount: number) =>
        `${company.currencySymbol} ${amount.toFixed(2)}`,
    },
    {
      title: "Payment Account",
      dataIndex: "paymentAccount",
      key: "paymentAccount",
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
    },
  ];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ margin: "2rem 0" }}
    >
      <Row>
        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.invoicePayments}
            loading={loading}
            pagination={false}
          />
        </Col>
      </Row>
    </motion.div>
  );
}

export default InvoicePaymentsSegment;
