import { useQueryClient } from "@tanstack/react-query";
import {
  Col,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Switch,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useInventoryServiceCreateBrand,
  useInventoryServiceGetBrandsKey,
} from "api/queries";
import { useEffect } from "react";

export const CreateBrandModal: React.FC<{
  isOpen: boolean;
  onClose: (success?: boolean) => void;
}> = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useInventoryServiceCreateBrand({
    onError: (error: any) => {
      notification.error({
        description:
          error?.body?.message ||
          error?.response?.data?.message ||
          error?.message,
        type: "error",
        message: "Failed to create brand",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetBrandsKey],
      });
      onClose(true);
    },
  });

  const handleSubmit = (values: any) => {
    mutate({ requestBody: values });
  };

  useEffect(() => {}, [isOpen]);

  return (
    <Modal
      title="Create Brand"
      open={isOpen}
      onCancel={() => onClose()}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            handleSubmit(values);
          })
          .catch((info) => {
            message.error(info.message || info);
          });
      }}
      confirmLoading={isPending}
      maskClosable={false}
      destroyOnClose
      cancelButtonProps={{ disabled: isPending }}
      okButtonProps={{ disabled: isPending }}
      afterOpenChange={(visible) => {
        form.resetFields();
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="brandName"
              label="Brand Name"
              rules={[
                {
                  required: true,
                  message: "Please enter brand name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name="brandDescription" label="Description">
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
