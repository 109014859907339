import { MinusOutlined } from "@ant-design/icons";
import { Button, Input, InputNumber, Select, Space } from "antd";
import { useInventoryServiceGetAllProducts } from "api/queries";
import ShopProductSelect from "components/common/ShopProductSelect";
import QueryString from "qs";
import { FC, useState } from "react";

interface IPurchaseOrderItemProps {
  item?: any;
  onChange: (index: number, value: any, itemDetails: any) => void;
  index: number;
  onQuantityChange: (index: number, value: number) => void;
  onClearItem: (index: number) => void;
  onUnitPriceChange: (index: number, value: number) => void;
  onRemoveItem: (index: number) => void;
}

export const PurchaseOrderItem: FC<IPurchaseOrderItemProps> = ({
  item,
  onChange,
  index,
  onQuantityChange,
  onUnitPriceChange,
  onClearItem,
  onRemoveItem,
}) => {
  const [filters, setFilters] = useState<any>("");

  const { data, isLoading, refetch } = useInventoryServiceGetAllProducts({
    filters,
    sort: "",
    pagination: QueryString.stringify({ offset: 0, limit: 100 }),
  });

  const handleSearch = (value: string) => {
    setFilters(
      QueryString.stringify({
        productName: {
          $ilike: `%${value}%`,
        },
      })
    );

    refetch();
  };

  return (
    <Space.Compact block style={{ margin: ".4rem 0" }}>
      <ShopProductSelect
        selectProps={{
          onClear: () => onClearItem(index),
          onMouseDown: (e) => e.preventDefault(),
          onSelect: (v, _opt) => {
            onChange(
              index,
              v,
              data?.items.find((i) => i.idProduct === v)
            );
          },
          onChange: (v, _opt) => {
            onChange(
              index,
              v,
              data?.items.find((i) => i.idProduct === v)
            );
          },
        }}
      />
      <InputNumber
        value={item?.quantity}
        placeholder="Quantity"
        style={{ width: "15%" }}
        min={1}
        onChange={(v) => onQuantityChange(index, v)}
      />
      <InputNumber
        value={item?.unitPrice}
        placeholder="Unit Price"
        style={{ width: "15%" }}
        onChange={(v) => onUnitPriceChange(index, v)}
      />
      <Input
        value={item?.totalPrice}
        placeholder="Total Price"
        style={{ width: "20%" }}
      />
      <Button icon={<MinusOutlined />} onClick={() => onRemoveItem(index)} />
    </Space.Compact>
  );
};
