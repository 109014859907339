import { Button, Col, Divider, Row, Space, Table, Typography } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import {
  useVendorServiceGetAllVendors,
  useVendorServiceGetAllVendorsKey,
  useVendorsServiceDeleteVendor,
} from "api/queries";
import { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import CreateVendorDialog from "./CreateVendorModal";
import EditVendorDialog from "./EditVendorModal";
import { motion } from "framer-motion";
import QueryString from "qs";
import { TableActions } from "components/common";
import { useNavigate } from "react-router-dom";
import useFilters, { FilterType } from "hooks/UseFilter";

export default function VendorListing() {
  const [createNewDialogOpen, setCreateNewDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [sortQuery, setSortQuery] = useState("");
  const [paginationString, setPaginationString] = useState("");

  const nav = useNavigate();

  const filtersDef: any[] = [
    {
      key: "vendorName",
      label: "Vendor Name",
      type: FilterType.Text,
    },
    {
      key: "description",
      label: "Description",
      type: FilterType.Text,
    },
    {
      key: "country",
      label: "Country",
      type: FilterType.Text,
    },
    {
      key: "address",
      label: "Address",
      type: FilterType.Text,
    },
    {
      key: "phone",
      label: "Phone",
      type: FilterType.Text,
    },
    {
      key: "email",
      label: "Email",
      type: FilterType.Text,
    },
    {
      key: "website",
      label: "Website",
      type: FilterType.Text,
    },
  ];

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, error, isError, refetch, isRefetching } =
    useVendorServiceGetAllVendors(
      {
        filters: filtersQuery,
        pagination: paginationString,
        sort: sortQuery,
      },
      [useVendorServiceGetAllVendorsKey],
      { enabled: true, staleTime: 1000 * 60 * 100 }
    );

  if (isError) {
    //@ts-ignore
    return <div>error: {error?.message || error}</div>;
  }

  const { mutate: deleteVendorMutation, isPending: isDeletePending } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useVendorsServiceDeleteVendor();

  const handleDeleteVendor = async (vendorId: number) => {
    await deleteVendorMutation(
      {
        vendorId,
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const handleOpenEditVendorModal = (vendor: any) => {
    setEditDialogOpen(true);
    setSelectedVendor(vendor);
  };

  const handleCloseEditVendorModal = () => {
    setEditDialogOpen(false);
  };

  const columns: ColumnsType = [
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      width: "auto",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 150,
      sorter: true,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 200,
      sorter: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 150,
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
      sorter: true,
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      width: 150,
      sorter: true,
    },
    {
      title: "Actions",
      render: (_text, record) => {
        return (
          <TableActions
            showLabel={false}
            onEdit={() => handleOpenEditVendorModal(record)}
            onDeletePopConfirm={() => handleDeleteVendor(record.idVendor)}
            onDeletePopConfirmMessage="Are you sure?"
            onDeletePopConfirmDescription="This vendor will be deleted permanently"
            onDeleteLoading={isDeletePending}
            onView={() => nav(`/purchases/vendors/${record.idVendor}`)}
          />
        );
      },
    },
  ];

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPaginationString(
        QueryString.stringify({
          limit: pagination.pageSize,
          offset: (pagination?.current - 1) * pagination.pageSize,
        })
      );
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    refetch();
  }, [sortQuery, filtersQuery, paginationString]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2} style={{ margin: "0px" }}>
            Vendors
          </Typography.Title>

          <Space>
            {FilterButton}
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => setCreateNewDialogOpen(true)}
            >
              Add new
            </Button>
          </Space>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={24}>
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            //@ts-ignore
            dataSource={data?.items}
            loading={isLoading || isRefetching || isDeletePending}
            pagination={{
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={onTableChange}
            key="vendor-list"
          />
        </Col>
      </Row>

      <CreateVendorDialog
        isOpen={createNewDialogOpen}
        onClose={() => {
          setCreateNewDialogOpen(false);
          refetch(); // Refetch the data when the dialog is closed
        }}
      />

      <EditVendorDialog
        isOpen={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
          setSelectedVendor(null);
          refetch(); // Refetch the data when the dialog is closed
        }}
        vendor={selectedVendor}
        vendorId={selectedVendor?.idVendor}
      />
    </motion.div>
  );
}
