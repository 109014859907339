import { QueryClient } from "@tanstack/react-query";
import { Col, Dropdown, Space } from "antd";
import { useEffect, useState } from "react";
import InvoicePriceDrawer from "./InvoicePrintDrawer";
import { IGetInvoiceDetailsResponse } from "api/requests";
import CreateInvoicePaymentModal from "./CreateInvoicePaymentModal";

export interface IInvoiceViewHeaderOptionsProps {
  status: any;
  queryClient: QueryClient;
  invoiceId: number;
  data: IGetInvoiceDetailsResponse;
}

function InvoiceViewHeaderOptions(props: IInvoiceViewHeaderOptionsProps) {
  const [showPrintableInvoice, setShowPrintableInvoice] =
    useState<boolean>(false);
  const [createPaymentModalOpen, setCreatePaymentModalOpen] =
    useState<boolean>(false);
  const [dropDownItems, setDropDownItems] = useState<any>([]);

  const handleOpenPrintDrawer = () => setShowPrintableInvoice(true);
  const handleClosePrintDrawer = () => setShowPrintableInvoice(false);

  const handleOpenCreatePaymentModal = () => setCreatePaymentModalOpen(true);
  const handleCloseCreatePaymentModal = () => setCreatePaymentModalOpen(false);

  useEffect(() => {
    const prevItems = [];
    if (
      props.data?.invoiceStatus === "SENT" ||
      props.data?.invoiceStatus === "PARTIAL" ||
      props.data?.invoiceStatus === "OVER_DUE"
    ) {
      prevItems.push({
        key: "create-payment",
        label: "Create Payment",
        onClick: handleOpenCreatePaymentModal,
      });
      setDropDownItems(prevItems);
    }
  }, [props.data]);

  return (
    <Col xs={12}>
      <Space
        align="center"
        direction="horizontal"
        // size="middle"
        style={{ justifyContent: "end", width: "100%" }}
      >
        <Dropdown.Button
          menu={{
            items: dropDownItems,
          }}
          onClick={handleOpenPrintDrawer}
        >
          Print
        </Dropdown.Button>
      </Space>

      <InvoicePriceDrawer
        onClose={handleClosePrintDrawer}
        open={showPrintableInvoice}
        data={props.data}
      />

      <CreateInvoicePaymentModal
        onClose={handleCloseCreatePaymentModal}
        open={createPaymentModalOpen}
        data={props.data}
        queryClient={props.queryClient}
      />
    </Col>
  );
}

export default InvoiceViewHeaderOptions;
