import { Form, Input, Button, Card, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import useAuth from "hooks/UseAuth";
import { useNavigate } from "react-router-dom";
import { useAuthServiceLogin } from "api/queries";

const LoginForm = () => {
  const auth = useAuth();
  const nav = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate: login, isPending } = useAuthServiceLogin({
    onError: (error: any) => {
      messageApi.open({
        type: "error",
        content: error?.message || error,
      });
    },
    onSuccess: (data) => {
      auth.login({
        token: data.token || "",
        user: data?.user?.userFullName || "",
        role: data?.user?.role,
        company: "", // data.user.companyName,
      });
      nav("/");
    },
  });

  const onFinish = (values: any) => {
    const { username, password } = values;
    login({ requestBody: { username, password } });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundImage: "./header-logo.png",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {contextHolder}
      <Card
        style={{
          width: 350,
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", // Soft shadow to match Shopking's style
        }}
      >
        {/* Logo at the top */}
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src="/header-logo.png" // Replace with your logo path
            alt="Logo"
            style={{ width: "150px", marginBottom: "10px" }}
          />
          <h2 style={{ fontWeight: "bold", color: "#333" }}>Login</h2>
        </div>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{
                width: "100%",
                // backgroundColor: "#52c41a", // Green color for the button to match Shopking style
                // borderColor: "#52c41a",
                height: "45px",
              }}
              loading={isPending}
              size="large"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
