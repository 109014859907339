import { PlusCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Table,
  notification,
} from "antd";
import { ColumnType } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import {
  useAccountingServiceDeleteTax,
  useAccountingServiceGetTaxes,
  useAccountingServiceGetTaxesKey,
} from "api/queries";
import { TableActions } from "components/common";
import { motion } from "framer-motion";
import queryString from "query-string";
import { useEffect, useState } from "react";
import CreateTaxModel from "./CreateTaxModal";
import EditTaxModal from "./EditTaxModal";

const TaxListingPage = () => {
  const [createTaxOpen, setCreateTaxOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortQuery, setSortQuery] = useState("");
  const [selectedTax, setSelectedTax] = useState<any>(null);
  const [updateTaxOpen, setUpdateTaxOpen] = useState(false);

  const queryClient = useQueryClient();

  const { data, isLoading } = useAccountingServiceGetTaxes(
    {
      pagination: queryString.stringify({ limit, offset }),
      filters: "",
      sort: sortQuery,
    },
    [useAccountingServiceGetTaxesKey]
  );

  const { mutate: deleteTax, isPending: isDeletePending } =
    useAccountingServiceDeleteTax();

  const handleOpenUpdateTax = (tax: any) => {
    setSelectedTax(tax);
    setUpdateTaxOpen(true);
  };

  const handleCloseUpdateTax = () => {
    setSelectedTax(null);
    setUpdateTaxOpen(false);
  };

  const handleDeleteTax = async (id: string) => {
    await deleteTax(
      {
        idTax: +id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [useAccountingServiceGetTaxesKey],
          });
        },
        onError: (error: any) => {
          notification.error({
            description:
              error?.body?.message ||
              error?.response?.data?.message ||
              error?.message,
            type: "error",
            message: "Failed to delete tax",
          });
        },
      }
    );
  };

  const hadnleOpenCreateTax = () => {
    setCreateTaxOpen(true);
  };

  const hadnleCloseCreateTax = async (reload?: boolean) => {
    setCreateTaxOpen(false);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    //handle sort
    if (sorter.order) {
      const s: any = {};
      s[sorter.field?.toString() || ""] =
        sorter.order === "ascend" ? "asc" : "desc";
      queryString.stringify(s);
      setSortQuery(s);
    } else {
      setSortQuery("");
    }
  };

  useEffect(() => {
    queryClient.refetchQueries({
      queryKey: [useAccountingServiceGetTaxesKey],
    });
  }, [sortQuery, limit, offset]);

  const columns: ColumnType<any>[] = [
    {
      title: "Title",
      dataIndex: "taxName",
      key: "taxName",
      sorter: true,
    },
    {
      title: "Rate",
      dataIndex: "taxRate",
      key: "taxRate",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "taxType",
      key: "taxType",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_v, row) => (
        <TableActions
          onEdit={() => handleOpenUpdateTax(row)}
          onDeletePopConfirm={() => handleDeleteTax(row.idTax.toString())}
          onDeletePopConfirmDescription="Are you sure you want to delete this tax?"
          onDeletePopConfirmMessage="Tax deleted successfully"
          onDeleteLoading={isDeletePending}
        />
      ),
    },
  ];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ position: "relative", height: "100%" }}
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2} style={{ margin: 0 }}>
            Tax
          </Typography.Title>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={hadnleOpenCreateTax}
          >
            Add New
          </Button>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items || []}
            loading={isLoading}
            key={"tax-list"}
            rowKey={(item) => item.idTax}
            pagination={{
              total: data?.total || 0,
              current: offset / limit + 1,
              pageSize: limit,
              showSizeChanger: true,
              onChange: (page, pageSize) => {
                setOffset((page - 1) * limit);
                setLimit(pageSize);
              },
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <CreateTaxModel
        isOpen={createTaxOpen}
        onClose={hadnleCloseCreateTax}
        queryClient={queryClient}
      />

      <EditTaxModal
        isOpen={updateTaxOpen && !!selectedTax}
        onClose={handleCloseUpdateTax}
        queryClient={queryClient}
        defaultValues={selectedTax}
      />
    </motion.div>
  );
};

export default TaxListingPage;
