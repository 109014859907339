import { Button, Drawer, Space } from "antd";

export interface CreateReparationProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateReparation = ({ isOpen, onClose }: CreateReparationProps) => {
  return (
    <Drawer
      title="Create Reparation"
      placement="bottom"
      closable={false}
      onClose={onClose}
      open={isOpen}
      height={"100%"}
      width={"100%"}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
        </Space>
      }
    >
      <div>Create Reparation</div>
    </Drawer>
  );
};

export default CreateReparation;
