import React, { useMemo } from "react";
import { Layout, Menu, theme } from "antd";
import { LaptopOutlined, UserOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import {
  IconAddressBook,
  IconBox,
  IconBrandProducthunt,
  IconBuildingWarehouse,
  IconCategory,
  IconClipboard,
  IconCoins,
  IconCreditCardPay,
  IconDevicesUp,
  IconFolderPlus,
  IconMoneybag,
  IconOld,
  IconPercentage,
  IconSettings,
  IconChartInfographic,
  IconList,
  IconReportMoney,
  IconBuildingBank,
  IconFileText,
  IconDeviceMobileX,
} from "@tabler/icons-react";
import ability from "utils/abilit";
import { PermissionType, SubjectKey } from "utils/enums";

const { useToken } = theme;
const { Sider } = Layout;

const LayoutSlider: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const { token } = useToken();
  const location = useLocation();
  const nav = useNavigate();

  // Memoize full menu items to avoid unnecessary recalculations
  const fullMenuItems = useMemo(
    () => [
      {
        key: "dashboard",
        icon: <LaptopOutlined />,
        label: "Dashboard",
        onClick: () => nav("/"),
      },
      {
        key: "accounting",
        label: "Accounting",
        type: "group",
        children: [
          {
            key: "/accounting/chart-of-accounts",
            label: "Chart of Accounts",
            icon: <IconList size={16} />,
            onClick: () =>
              nav("/accounting/chart-of-accounts", { replace: true }),
            subject: SubjectKey.ACCOUNT,
          },
          {
            key: "/accounting/transaction-listing",
            label: "Transaction Listing",
            icon: <IconReportMoney size={16} />,
            onClick: () =>
              nav("/accounting/transaction-listing", { replace: true }),
            subject: SubjectKey.TRANSACTION,
          },
          {
            key: "/accounting/bank-accounts",
            label: "Bank Accounts",
            icon: <IconBuildingBank size={16} />,
            onClick: () => nav("/accounting/bank-accounts", { replace: true }),
            subject: SubjectKey.BANK_ACCOUNT,
          },
          {
            key: "/accounting/expense-categories",
            label: "Expense Categories",
            icon: <IconMoneybag size={16} />,
            onClick: () =>
              nav("/accounting/expense-categories", { replace: true }),
            subject: SubjectKey.EXPENSE_CATEGORY,
          },
          {
            key: "/accounting/expenses",
            label: "Expenses",
            icon: <IconCreditCardPay size={16} />,
            onClick: () => nav("/accounting/expenses", { replace: true }),
            subject: SubjectKey.EXPENSE,
          },
          {
            key: "/accounting/tax",
            label: "Taxes",
            icon: <IconPercentage size={16} />,
            onClick: () => nav("/accounting/tax", { replace: true }),
            subject: SubjectKey.TAXES,
          },
          {
            key: "/accounting/currencies",
            label: "Currencies",
            icon: <IconCoins size={16} />,
            onClick: () => nav("/accounting/currencies", { replace: true }),
            subject: SubjectKey.CURRENCY,
          },
        ],
      },
      {
        key: "/sales",
        label: "Sales",
        type: "group",
        children: [
          {
            key: "/sales/customers",
            label: "Customers",
            icon: <IconAddressBook size={16} />,
            onClick: () => nav("/sales/customers", { replace: true }),
            subject: SubjectKey.CUSTOMER,
          },
          {
            key: "/sales/reparations",
            label: "Reparations",
            icon: <IconDeviceMobileX size={16} />,
            onClick: () => nav("/sales/reparations", { replace: true }),
            // subject: SubjectKey.REPARATIONS,
          },
          {
            key: "/sales/invoices",
            label: "Invoices",
            icon: <IconFileText size={16} />,
            onClick: () => nav("/sales/invoices", { replace: true }),
            subject: SubjectKey.INVOICE,
          },
        ],
      },
      {
        key: "/purchases",
        label: "Purchases",
        type: "group",
        children: [
          {
            key: "/purchases/vendors",
            label: "Vendors",
            icon: <IconOld size={16} />,
            onClick: () => nav("/purchases/vendors", { replace: true }),
            subject: SubjectKey.VENDOR,
          },
          {
            key: "/purchases/purchase-orders",
            label: "Purchase Orders",
            icon: <IconClipboard size={16} />,
            onClick: () => nav("/purchases/purchase-orders", { replace: true }),
            subject: SubjectKey.PURCHASE_ORDER,
          },
        ],
      },
      {
        key: "/inventory",
        label: "Inventory",
        type: "group",
        children: [
          {
            key: "/inventory/stock",
            label: "Stock",
            icon: <IconList size={16} />,
            onClick: () => nav("/inventory/stock", { replace: true }),
            // subject: SubjectKey.INVENTORY_STOCK,
          },
          {
            key: "/inventory/transactions",
            label: "Transactions",
            icon: <IconList size={16} />,
            onClick: () => nav("/inventory/transactions", { replace: true }),
            subject: SubjectKey.INVENTORY_TRANSACTION,
          },
          {
            key: "/inventory/products",
            label: "Products",
            icon: <IconBox size={16} />,
            onClick: () => nav("/inventory/products", { replace: true }),
            subject: SubjectKey.PRODUCTS,
          },
          {
            key: "/inventory/product-category",
            label: "Product Category",
            icon: <IconCategory size={16} />,
            onClick: () =>
              nav("/inventory/product-category", { replace: true }),
            subject: SubjectKey.PRODUCT_CATEGORY,
          },
          {
            key: "/inventory/brands",
            label: "Brands",
            icon: <IconBrandProducthunt size={16} />,
            onClick: () => nav("/inventory/brands", { replace: true }),
            subject: SubjectKey.BRAND,
          },
          {
            key: "/inventory/services",
            label: "Services",
            icon: <IconDevicesUp size={16} />,
            onClick: () => nav("/inventory/services", { replace: true }),
            subject: SubjectKey.SERVICE,
          },
          {
            key: "/inventory/service-category",
            label: "Service Category",
            icon: <IconFolderPlus size={16} />,
            onClick: () =>
              nav("/inventory/service-category", { replace: true }),
            subject: SubjectKey.SERVICE_CATEGORY,
          },
          {
            key: "/inventory/location",
            label: "Location",
            icon: <IconBuildingWarehouse size={16} />,
            onClick: () => nav("/inventory/location", { replace: true }),
            subject: SubjectKey.LOCATION,
          },
        ],
      },
      {
        type: "divider",
        key: "settings-divider2",
      },
      {
        key: "/users",
        icon: <UserOutlined />,
        label: "Users",
        onClick: () => nav("/users"),
        subject: SubjectKey.USER,
      },
      {
        key: "/reports",
        icon: <IconChartInfographic size={16} />,
        label: "Reports",
        onClick: () => nav("/reports"),
        subject: SubjectKey.REPORTS,
      },
      {
        type: "divider",
        key: "settings-divider",
      },
      {
        key: "/settings",
        label: "Settings",
        icon: <IconSettings size={16} />,
        onClick: () => nav("/settings"),
        subject: SubjectKey.SETTINGS,
      },
    ],
    [nav]
  );

  // Function to filter menu items based on permissions
  const filterMenuItems = (items: any[]): any[] => {
    return items.reduce((acc: any[], item: any) => {
      if (item.children) {
        item.children = filterMenuItems(item.children);
        if (
          item.children.length > 0 ||
          (item.subject && ability.can(PermissionType.READ, item.subject))
        ) {
          acc.push(item);
        }
      } else {
        if (!item.subject || ability.can(PermissionType.READ, item.subject)) {
          acc.push(item);
        }
      }
      return acc;
    }, []);
  };

  // Memoize filtered menu items
  const filteredMenuItems = useMemo(() => {
    return filterMenuItems(fullMenuItems);
  }, [fullMenuItems]);

  return (
    <Sider
      width={200}
      collapsed={collapsed}
      style={{
        height: "100%",
        overflow: "auto",
        background: token.colorBgBase,
      }}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={[location.pathname, location.pathname.split("/")[1]]}
        style={{
          height: "100%",
          borderRight: 0,
          background: token.colorBgBase,
        }}
        items={filteredMenuItems}
      />
    </Sider>
  );
};

export default LayoutSlider;
