import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Button, Col, Divider, Row, Space, Table, TablePaginationConfig, Typography, Tag } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CreateReparation from "./CreateReparation";

export default function ReparationList() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [createReparationDrawerOpen, setCreateReparationDrawerOpen] = useState(false);

  // Dummy data
  const data = [
    { id: '1', customer: 'John Doe', phone: '123-456-7890', status: 'In Progress', date: '2024-10-28' },
    { id: '2', customer: 'Jane Smith', phone: '987-654-3210', status: 'Completed', date: '2024-10-27' },
    { id: '3', customer: 'Mike Johnson', phone: '555-123-4567', status: 'Pending', date: '2024-10-26' },
  ];

  const columns = [
    { title: "Customer", dataIndex: "customer", key: "customer" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { 
      title: "Status", 
      dataIndex: "status", 
      key: "status",
      render: (status: string) => {
        const color = status === 'Completed' ? 'green' : status === 'In Progress' ? 'blue' : 'volcano';
        return <Tag color={color}>{status}</Tag>;
      },
    },
    { 
      title: "Date", 
      dataIndex: "date", 
      key: "date",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => (
        <Button onClick={() => handleDeleteReparation(record.id)}>Delete</Button>
      ),
    },
  ];

  const handleDeleteReparation = (id: string) => {
    console.log("Delete Reparation with ID:", id); // Placeholder for delete action
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current || 1);
    setLimit(pagination.pageSize || 10);
  };

  useEffect(() => {
    // Effect could trigger data refetch if using API in the future
  }, [sortQuery, limit, page]);

  const handleCreateReparation = () => {
    setCreateReparationDrawerOpen(true);
  };

  const handleCloseCreateReparation = () => {
    setCreateReparationDrawerOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <Row>
        <Col xs={12}>
          <Typography.Title>Reparations</Typography.Title>
        </Col>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Space>
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={handleCreateReparation}
            >
              Add New
            </Button>
          </Space>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={data}
            pagination={{
              total: data.length,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
            rowKey={(item) => item.id}
          />
        </Col>

        <CreateReparation
          isOpen={createReparationDrawerOpen}
          onClose={handleCloseCreateReparation}
        />
      </Row>
    </motion.div>
  );
}