import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tag,
} from "antd";
import { useState } from "react";
import { useInventoryServiceCreateInventoryTransaction } from "api/queries";
import { ICreateInventoryTransactionInput } from "api/requests";
import LocationSelect from "components/common/LocationSelect";
import ProductSelect from "components/common/ProductSelect";
import ShopProductSelect from "components/common/ShopProductSelect";
import AllProductSelect from "components/common/AllProductsSelect";

export interface CreateTransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
}

export default function CreateTransactionModal({
  isOpen,
  onClose,
  queryClient,
}: CreateTransactionModalProps) {
  const { mutate, isPending } = useInventoryServiceCreateInventoryTransaction({
    onSuccess: async () => {
      await queryClient.invalidateQueries("inventory-transactions");
      onClose();
      form.resetFields();
    },
    onError: (e: any) => {
      message.error(e?.message || "Failed to create transaction");
    },
  });

  const [form] = Form.useForm();
  const [transactionType, setTransactionType] = useState<string | undefined>();

  const onFinish = async (values: ICreateInventoryTransactionInput) => {
    await mutate({
      requestBody: values,
    });
  };

  const handleValuesChange = (changedValues: any) => {
    if (changedValues.transactionType) {
      setTransactionType(changedValues.transactionType);
    }
  };

  return (
    <Modal
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      open={isOpen}
      onCancel={() => onClose()}
      onOk={() => form.submit()}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
        onFinishFailed={() => {
          message.error("Failed to create transaction");
        }}
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="product"
              label="Product"
              rules={[{ required: true }]}
            >
              <AllProductSelect isForm={true} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[{ required: true }, { min: 1, type: "number" }]}
            >
              <InputNumber min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="transactionDate"
              label="Transaction Date"
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="transactionType"
              label="Transaction Type"
              rules={[{ required: true }]}
            >
              <Select style={{ width: "100%" }}>
                <Select.Option value="TRANSFER">TRANSFER</Select.Option>
                <Select.Option value="ADJUSTMENT">ADJUSTMENT</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {transactionType && (
            <>
              {transactionType === "TRANSFER" && (
                <Col xs={12}>
                  <Form.Item
                    name="locationFrom"
                    label="Location From"
                    rules={[{ required: true }]}
                  >
                    <LocationSelect isForm={true} />
                  </Form.Item>
                </Col>
              )}

              <Col xs={transactionType === "TRANSFER" ? 12 : 24}>
                <Form.Item
                  name="locationTo"
                  label="Location To"
                  rules={[{ required: true }]}
                >
                  <LocationSelect isForm={true} />
                </Form.Item>
              </Col>
            </>
          )}

          <Col xs={24}>
            <Form.Item
              name="inventoryTransactionDescription"
              label="Transaction Description"
              rules={[{ required: false }]}
            >
              <Input.TextArea style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
