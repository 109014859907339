import { QueryClient } from "@tanstack/react-query";
import {
  Alert,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Switch,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useInventoryServiceGetBrandsKey,
  useInventoryServiceUpdateBrand,
} from "api/queries";
import { useEffect } from "react";

export interface IEditBrandModalProps {
  isOpen: boolean;
  onClose: (isSuccess?: boolean) => void;
  queryClient: QueryClient;
  defaultValues: any;
}

export const EditBrandModal: React.FC<IEditBrandModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  defaultValues,
}) => {
  const [form] = Form.useForm();

  const { mutate, isPending, error, isError } = useInventoryServiceUpdateBrand({
    onError: (error: any) => {
      notification.error({
        description:
          error?.body?.message ||
          error?.response?.data?.message ||
          error?.message,
        type: "error",
        message: "Failed to edit brand",
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetBrandsKey],
        refetchType: "all",
      });
      onClose();
    },
  });

  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
      idBrand: defaultValues.idBrand,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...defaultValues,
    });
  }, [form, defaultValues]);

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={form.submit}
      confirmLoading={isPending}
      okText="Update"
      cancelText="Cancel"
      destroyOnClose
      afterClose={() => form.resetFields()}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        name="edit-brand-form"
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="brandName"
              label="Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name="brandDescription" label="Description">
              <TextArea />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name="isActive" label="Is Active">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
