import {
  Table,
  Typography,
  Space,
  Row,
  Col,
  Button,
  Drawer,
} from "antd";
import { PrinterOutlined } from "@ant-design/icons";

import "./StatementOfAccount.css";
import dayjs from "dayjs";

const { Title, Text } = Typography;

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (amount: number, record: any) => (
      <span style={{ color: record.type === "PAYMENT" ? "green" : "red" }}>
        {record.type === "PAYMENT" ? `${amount}` : `+${amount}`}
      </span>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
];

const StatementOfAccountPrintModal = ({
  data,
  isOpen,
  onClose,
  vendorName,
  startDate,
  endDate,
  vendorAddress,
}: any) => {
  const calculateTotal = () => {
    const totalPurchase = data
      .filter((item: any) => item.type === "PURCHASE")
      .reduce((acc: any, item: any) => acc + (Number(item?.amount) || 0), 0);
    const totalPayment = data
      .filter((item: any) => item.type === "PAYMENT")
      .reduce((acc: any, item: any) => acc + (Number(item?.amount) || 0), 0);
    return totalPurchase - totalPayment;
  };


  return (
    <Drawer open={isOpen} onClose={onClose} placement="bottom" height={"100%"}>
      <div className="printable-container">
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={2}>Statement of Account</Title>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PrinterOutlined />}
              onClick={() => window.print()}
            >
              Print
            </Button>
          </Col>
        </Row>
        <Row
          justify="space-between"
          align="middle"
          className="printable-header"
        >
          <Col>
            <p>
              <strong>Supplier:</strong> {vendorName}
            </p>
            <p>
              <strong>Address:</strong> {vendorAddress}
            </p>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <p>
              <strong>Statement Date:</strong>{" "}
              {startDate && endDate ? `${dayjs(startDate).format("YYYY-MM-DD")} to ${dayjs(endDate).format("YYYY-MM-DD")}` : dayjs().format("YYYY-MM-DD")}
            </p>
            <p style={{ opacity: 0 }}>
              {/* <strong>Account Number:</strong> 000123456 */}
              <strong>lksjdf</strong>
            </p>
          </Col>
        </Row>
        <Row
          justify="space-between"
          align="middle"
          className="printable-filter"
        ></Row>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className="printable-table"
        />
        <Row justify="end" className="printable-total">
          <Col>
            <Space>
              <Text strong>Total Remaining:</Text>
              <Text>{calculateTotal()}</Text>
            </Space>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};

export default StatementOfAccountPrintModal;
