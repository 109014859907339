import React from "react";
import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useQueryClient } from "@tanstack/react-query";
import { useSalesServiceGetInvoiceDetails } from "api/queries";
import { Col, Divider, Row, Segmented, Skeleton, Typography } from "antd";
import InvoiceViewHeaderOptions from "./InvoiceViewHeader";
import InvoiceDetailsSegment from "./InvoiceDetailsSegment";
import InvoiceHistory from "./InvoiceHistory";
import InvoicePaymentsSegment from "./InvoicePaymentsSegment";

function InvoiceDetails() {
  const [currentOption, setCurrentOption] = React.useState<
    "details" | "history" | "payments"
  >("details");

  const params: any = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching, refetch } =
    useSalesServiceGetInvoiceDetails({ idInvoice: +params.id });

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.2 }}
    >
      <Row>
        <Col xs={12}>
          <Typography.Title level={2} style={{ margin: 0 }}>
            <Skeleton
              paragraph={false}
              title={true}
              active={isLoading || isRefetching}
              loading={isLoading || isRefetching}
            >
              {data?.invoiceNumber}
            </Skeleton>
          </Typography.Title>
        </Col>

        {data?.invoiceStatus && (
          <InvoiceViewHeaderOptions
            status={data?.invoiceStatus}
            queryClient={queryClient}
            invoiceId={data?.idInvoice}
            data={data}
          />
        )}

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={24}>
          <Segmented
            options={[
              { label: "Details", value: "details" },
              { label: "History", value: "history" },
              { label: "Payments", value: "payments" },
            ]}
            value={currentOption}
            onChange={(value: any) => setCurrentOption(value)}
          />
        </Col>

        <Col xs={24}>
          <AnimatePresence>
            {currentOption === "details" && (
              <InvoiceDetailsSegment data={data} loading={isLoading} />
            )}

            {currentOption === "history" && <InvoiceHistory data={data} />}

            {currentOption === "payments" && (
              <InvoicePaymentsSegment data={data} loading={isLoading} />
            )}
          </AnimatePresence>
        </Col>
      </Row>
    </motion.div>
  );
}

export default InvoiceDetails;
