import {
  Button,
  Col,
  Descriptions,
  List,
  Row,
  Skeleton,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { DescriptionsItemType } from "antd/es/descriptions";
import dayjs from "dayjs";
import InvoiceStatusComponent from "components/common/InvoiceStatus";
import { IGetInvoiceDetailsResponse } from "api/requests";

export interface IInvoiceDetailsSegmentProps {
  data: IGetInvoiceDetailsResponse | undefined;
  loading: boolean;
}

function InvoiceDetailsSegment({ data, loading }: IInvoiceDetailsSegmentProps) {
  const nav = useNavigate();

  const company = useSelector((state: any) => state.company);

  const descriptionItems: DescriptionsItemType[] = [
    {
      key: "invoiceNumber",
      label: "Number",
      children: data?.invoiceNumber,
    },
    {
      key: "customerName",
      label: "Customer Name",
      children: data?.customer?.customerName,
    },
    {
      key: "customerPhone",
      label: "Customer Phone",
      children: data?.customer?.customerPhone,
    },
    {
      key: "invoiceDate",
      label: "Date",
      children: dayjs(data?.invoiceDate).format("DD/MM/YYYY"),
    },
    {
      key: "invoiceDueDate",
      label: "Due Date",
      children: dayjs(data?.invoiceDueDate).format("DD/MM/YYYY"),
    },
    {
      key: "invoiceStatus",
      label: "Status",
      children: <InvoiceStatusComponent status={data?.invoiceStatus as any} />,
    },
    {
      key: "taxType",
      label: "Tax Type",
      children: data?.tax?.taxType ?? "-",
    },
    {
      key: "taxRate",
      label: "Tax Rate",
      children: data?.tax?.taxRate ?? "-",
    },
    {
      key: "taxTotal",
      label: "Tax Total",
      children: data?.taxAmount
        ? `${data?.taxAmount} ${company.currencySymbol}`
        : "-",
    },
    {
      key: "discountScheme",
      label: "Discount Scheme",
      children: data?.discountScheme ?? "-",
    },
    {
      key: "discountAmount",
      label: "Discount Amount",
      children: data?.discountAmount ?? "-",
    },
    {
      key: "invoiceDiscountTotal",
      label: "Discount Total",
      children: data?.invoiceDiscountTotal
        ? `${data?.invoiceDiscountTotal} ${company.currencySymbol}`
        : "-",
    },
    {
      key: "totalPaidAmount",
      label: "Paid Amount",
      children:
        data?.totalPaidAmount && data?.totalPaidAmount > 0
          ? `${data?.totalPaidAmount} ${company.currencySymbol}`
          : "-",
    },
    {
      key: "invoiceTotal",
      label: "Total",
      children: `${data?.invoiceTotalAmount} ${company.currencySymbol}`,
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={24}>
          <Skeleton loading={!!loading} active>
            <Descriptions
              style={{ width: "100%", margin: "2rem 0" }}
              layout="vertical"
              items={descriptionItems}
            />
          </Skeleton>
        </Col>

        <Col xs={24} style={{ margin: "1rem 0" }}>
          <List
            loading={!!loading}
            renderItem={(item: any, index) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    onClick={() =>
                      nav(`/inventory/products/${item.product?.idProduct}`)
                    }
                  >
                    View
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={item.product?.productName}
                  description={
                    <Typography.Text>
                      {item.quantity} x {item.unitPrice}{" "}
                      {company.currencySymbol}
                    </Typography.Text>
                  }
                  key={index}
                />
                <List.Item.Meta
                  title={""}
                  description={
                    <Typography.Text>
                      {item.totalPrice} {company.currencySymbol}
                    </Typography.Text>
                  }
                />
              </List.Item>
            )}
            dataSource={data?.invoiceItems}
          />
        </Col>
      </Row>
    </motion.div>
  );
}

export default InvoiceDetailsSegment;
