import { QueryClient } from "@tanstack/react-query";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import {
  useAccountingServiceGetCurrencies,
  useInventoryServiceCreatePurchaseOrder,
  useInventoryServiceGetPurchaseOrdersKey,
} from "api/queries";
import VendorSelect from "components/common/VendorSelect";
import { useEffect, useState } from "react";
import { ICreatePurchaseOrderInput, IVendor } from "api/requests";
import { IconDotsCircleHorizontal } from "@tabler/icons-react";
import { ItemSelection } from "./components/ItemSelection";
import { MinusOutlined } from "@ant-design/icons";
import TaxSelect from "components/common/TaxSelect";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import CurrencySelect from "components/common/CurrencySelect";
import QueryString from "qs";

const CreatePurchaseOrderDrawer: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
}> = ({ isOpen, onClose, queryClient }) => {
  const [selectedVendor, setSelectedVendor] = useState<IVendor | null>(null);

  //forms values
  const [hasTax, setHasTax] = useState(false);
  const [selectedTax, setSelectedTax] = useState<any | null>(null);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);
  const [hasShipping, setHasShipping] = useState(false);
  const [hasCurrency, setHasCurrency] = useState(false);
  const [shippingAmount, setShippingAmount] = useState<number>(0);
  const [hasNotes, setHasNotes] = useState(false);
  // const [shippingType, setShippingType] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [totalTax, setTotalTax] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalSubtotal, setTotalSubtotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<any | null>(null);
  const [exchangeRateAmount, setExchangeRateAmount] = useState<number>(1);
  const [exchangeRateView, setExchangeRateView] = useState<string>("");

  const [form] = Form.useForm();

  const company = useSelector((state: any) => state.company);

  const { mutate, isPending } = useInventoryServiceCreatePurchaseOrder({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetPurchaseOrdersKey],
      });
      form.resetFields();
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.message || err || "Failed to create purchase order");
    },
  });

  const onFinish = (values: any) => {
    const input: ICreatePurchaseOrderInput = {
      purchaseOrderNumber: values.purchaseOrderNumber,
      //@ts-ignore
      purchaseOrderDate: dayjs(values.dateOfIssue).toDate(),
      //@ts-ignore
      purchaseOrderDeliveryDate: dayjs(values.deliveryDate).toDate(),
      // purchaseOrderSubject: values.purchaseOrderSubject,
      vendor: selectedVendor?.idVendor,
      purchaseOrderItems: selectedItems.map((item: any) => ({
        product: item.idProduct,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        totalPrice: item.totalPrice,
      })),
      shippingType: values.shippingType,
      shippingCharge: values.shippingAmount,
      discountScheme: values.discountType,
      discountAmount: values.discountAmount,
      tax: selectedTax?.idTax || null,
      taxTotal: totalTax,
      purchaseOrderNotes: values.notes,
      exchangeRateAmount,
      currency: selectedCurrency?.idCurrency || null,
    };

    mutate({ requestBody: input});
  };

  useEffect(() => {
    let disc = 0;
    let tax = 0;

    const subtotal = selectedItems.reduce(
      (acc, item) => acc + item.totalPrice,
      0
    );

    if (selectedTax?.idTax) {
      if (selectedTax?.taxType === "PERCENTAGE") {
        tax = (subtotal * (selectedTax?.taxRate || 0)) / 100;
        tax = Number(tax.toFixed(2));
      } else {
        tax = selectedTax?.taxRate || 0;
      }
    }

    let total = tax + shippingAmount + subtotal;

    if (hasDiscount) {
      if (discountType === "PERCENTAGE") {
        disc = (subtotal * (discountAmount || 0)) / 100;
        disc = Number(disc.toFixed(2));
      } else {
        disc = discountAmount || 0;
      }
      setTotalDiscount(disc);
      setTotalSubtotal(subtotal);
      setTotalTax(tax);
      setTotal(Number((total - disc).toFixed(2)));
      return;
    }

    setTotalDiscount(disc);
    setTotalSubtotal(subtotal);
    setTotalTax(tax);
    setTotal(Number((total - disc).toFixed(2)));
  }, [
    selectedItems,
    discountAmount,
    shippingAmount,
    totalSubtotal,
    discountType,
    hasDiscount,
    selectedTax,
    selectedCurrency
  ]);

  const handleAddShipping = () => {
    form.setFieldValue("shippingAmount", 0);
    setHasShipping(true);
  };

  const handleRemoveShipping = () => {
    form.setFieldValue("shippingAmount", null);
    setShippingAmount(0);
    setHasShipping(false);
  };

  const handleAddTax = () => {
    setHasTax(true);
  };

  const handleRemoveTax = () => {
    setSelectedTax(null);
    setHasTax(false);
  };

  const handleAddDiscount = () => {
    setHasDiscount(true);
    setDiscountAmount(0);
    setDiscountType("PERCENTAGE");
  };

  const handleDiscountChange = (v?: number) => {
    setDiscountAmount(v || 0);
  };

  const handleRemoveDiscount = () => {
    setTotalDiscount(0);
    setDiscountType(null);
    setDiscountAmount(0);
    form.setFieldValue("discountType", null);
    form.setFieldValue("discountAmount", null);
    setHasDiscount(false);
  };

  const handleAddNotes = () => {
    setHasNotes(true);
  };

  const handleRemoveNotes = () => {
    setHasNotes(false);
  };

  const handleAddCurrency = () => {
    setHasCurrency(true);
  };

  const handleRemoveCurrency = () => {
    setSelectedCurrency(null);
    setHasCurrency(false);
    setExchangeRateAmount(1);
    form.setFieldsValue({ currency: null, exchangeRate: 1 });
  };

  const { data: currencyData } = useAccountingServiceGetCurrencies({
    filters: "",
    sort: "",
    pagination: QueryString.stringify({ limit: 100, offset: 0 }),
  });

  const handleCurrencyChange = () => {
    const currencyRateID = form.getFieldValue("currency");
    if (!currencyRateID) {
      setExchangeRateView("");
      return;
    }

    const currency = currencyData?.items?.find(
      (i: any) => i.idCurrency === currencyRateID
    );

    //@ts-ignore
    form.setFieldsValue({ exchangeRate: currency?.exchangeRate?.rate });
    //@ts-ignore
    setExchangeRateAmount(currency?.exchangeRate?.rate);

    setSelectedCurrency(currency);
  };

  const handleExchangeRateChange = () => {
    const currencyRateID = form.getFieldValue("exchangeRate");

    if (!currencyRateID && selectedCurrency) {
      form.setFieldValue("exchangeRate", selectedCurrency?.exchangeRate?.rate);
      setExchangeRateAmount(selectedCurrency?.exchangeRate?.rate);
      return;
    }

    setExchangeRateAmount(currencyRateID);
    
  };

  const handleExchangeRateView = () => {
    
    const amount = total;

    if (!amount) {
      setExchangeRateView("");
    }

    //@ts-ignore
    setExchangeRateView(
      `${amount} ${selectedCurrency?.currencySymbol} / ${
        //@ts-ignore
        exchangeRateAmount
        //@ts-ignore
      } = ${(amount / exchangeRateAmount).toFixed(2)} ${
        company.currencySymbol
      }`
    );
  };

  useEffect(() => {
    handleExchangeRateView();
  }, [exchangeRateAmount, selectedCurrency, total]);

  return (
    <Modal
      title="Create Purchase Order"
      open={isOpen}
      onCancel={onClose}
      style={{ maxWidth: 600, width: "100%" }}
      width={"100%"}
      onOk={form.submit}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} disabled={isPending}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={12}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24}>
                <Form.Item
                  label="Purchase Order Number (optional)"
                  name="purchaseOrderNumber"
                  rules={[{ required: false }]}
                >
                  <Input
                    placeholder="Purchase Order Number"
                    name="purchaseOrderNumber"
                  />
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item
                  label="Date Of Issue"
                  name="dateOfIssue"
                  rules={[{ required: true }]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item
                  label="Delivery Date"
                  name="deliveryDate"
                  rules={[{ required: true }]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  label="Subject"
                  name="purchaseOrderSubject"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={12}>
            <Space
              direction="vertical"
              style={{ width: "100%" }}
              align="start"
              styles={{ item: { width: "100%" } }}
            >
              <Typography>Vendor details</Typography>

              <VendorSelect
                value={selectedVendor?.idVendor}
                onChange={setSelectedVendor}
                selectProps={{ style: { width: "100%" } }}
              />

              <Typography style={{ marginTop: 18, color: "#999999" }}>
                {selectedVendor?.country}
              </Typography>
              <Typography style={{ color: "#999999" }}>
                {selectedVendor?.city || "----"}
              </Typography>
              <Typography style={{ color: "#999999" }}>
                {selectedVendor?.address || "----"}
              </Typography>
              <Typography style={{ color: "#999999" }}>
                {selectedVendor?.phone || "----"}
              </Typography>
              <Typography style={{ color: "#999999" }}>
                {selectedVendor?.email || "----"}
              </Typography>
            </Space>
          </Col>

          <Col xs={24}>
            <Divider
              orientation="right"
              key="additionalDetails-divider"
              children={[
                <Dropdown
                  key="additionalDetails-dropdown"
                  menu={{
                    items: [
                      {
                        key: "1tax-taxdropdown",
                        label: "Add Tax",
                        disabled: hasTax,
                        onClick: handleAddTax,
                      },
                      {
                        key: "2discount-discountdropdown",
                        label: "Add Discount",
                        disabled: hasDiscount,
                        onClick: handleAddDiscount,
                      },
                      {
                        key: "3shipping-shippingdropdown",
                        label: "Add Shipping",
                        disabled: hasShipping,
                        onClick: handleAddShipping,
                      },
                      {
                        key: "4notes-dropshownit",
                        label: "Add Notes",
                        disabled: hasNotes,
                        onClick: handleAddNotes,
                      },
                      {
                        key: "5currency-dropshownit",
                        label: "Add Currency",
                        disabled: hasCurrency,
                        onClick: handleAddCurrency,
                      }
                    ],
                  }}
                >
                  <Button
                    type="text"
                    key="additionalDetails"
                    icon={
                      <IconDotsCircleHorizontal size={16} className="anticon" />
                    }
                  >
                    Additional Details
                  </Button>
                </Dropdown>,
              ]}
              // style={{ opacity: 0.5 }}
            />
          </Col>

          {hasShipping && (
            <>
              <Col xs={8}>
                <Form.Item
                  label="Shipping Type"
                  name="shippingType"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Shipping Type"
                    options={[
                      { label: "Standard", value: "STANDARD" },
                      { label: "Express", value: "EXPRESS" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  label="Shipping Amount"
                  name="shippingAmount"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    onChange={(v) => setShippingAmount(v as number)}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Flex
                  justify="center"
                  align="center"
                  style={{ height: "100%" }}
                >
                  <Button
                    type="text"
                    size="small"
                    icon={<MinusOutlined size={16} className="anticon" />}
                    onClick={handleRemoveShipping}
                  >
                    Remove Shipping
                  </Button>
                </Flex>
              </Col>
            </>
          )}

          {hasDiscount && (
            <>
              <Col xs={8}>
                <Form.Item
                  label="Discount Type"
                  name="discountType"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Discount Type"
                    options={[
                      { label: "Percentage", value: "PERCENTAGE" },
                      { label: "Fixed Amount", value: "FIXED_AMOUNT" },
                    ]}
                    value={discountType}
                    onChange={(v) => setDiscountType(v as string)}
                  />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  label="Discount Amount"
                  name="discountAmount"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    onChange={(v) => handleDiscountChange(v as number)}
                    style={{ width: "100%" }}
                    value={discountAmount}
                  />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Flex
                  justify="center"
                  align="center"
                  style={{ height: "100%" }}
                >
                  <Button
                    type="text"
                    size="small"
                    icon={<MinusOutlined size={16} className="anticon" />}
                    onClick={handleRemoveDiscount}
                  >
                    Remove Discount
                  </Button>
                </Flex>
              </Col>
            </>
          )}

          {hasTax && (
            <>
              <Col xs={16}>
                <TaxSelect
                  value={selectedTax?.idTax}
                  onChange={setSelectedTax}
                  selectProps={{ style: { width: "100%" } }}
                  isForm={false}
                />
              </Col>
              <Col xs={8}>
                <Flex
                  justify="center"
                  align="center"
                  style={{ height: "100%" }}
                >
                  <Button
                    type="text"
                    size="small"
                    icon={<MinusOutlined size={16} className="anticon" />}
                    onClick={handleRemoveTax}
                  >
                    Remove Tax
                  </Button>
                </Flex>
              </Col>
            </>
          )}

          {hasCurrency && (
            <>
              <Col xs={8}>
                <Form.Item
                  label="Currency"
                  name="currency"
                  rules={[{ required: true }]}
                >
                  <CurrencySelect isForm={true} selectProps={{ onSelect: () => handleCurrencyChange() }} />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  label="Exchange Rate"
                  name="exchangeRate"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0.01} style={{ width: "100%" }} onInput={handleExchangeRateChange} />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Flex
                  justify="center"
                  align="center"
                  style={{ height: "100%" }}
                >
                  <Button
                    type="text"
                    size="small"
                    icon={<MinusOutlined size={16} className="anticon" />}
                    onClick={handleRemoveCurrency}
                  >
                    Remove Currency
                  </Button>
                </Flex>
              </Col>
              <Col xs={24}>
                <span>{exchangeRateView}</span>
              </Col>  
            </>
          )}

          {hasNotes && (
            <>
              <Col xs={24}>
                <Form.Item
                  label="Notes"
                  name="notes"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    placeholder="Notes"
                    name="notes"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Flex
                  justify="flex-end"
                  align="flex-start"
                  style={{ height: "100%" }}
                >
                  <Button
                    type="text"
                    size="small"
                    icon={<MinusOutlined size={16} className="anticon" />}
                    onClick={handleRemoveNotes}
                  >
                    Remove Notes
                  </Button>
                </Flex>
              </Col>
            </>
          )}

          <Col xs={24}>
            <Typography.Title level={5}>Items</Typography.Title>
          </Col>

          <Col xs={24}>
            <ItemSelection
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          </Col>

          <Col xs={24}>
            <Flex
              vertical
              style={{ width: "100%" }}
              justify="flex-end"
              align="flex-end"
            >
              <Space>
                <Typography
                  style={{ fontWeight: "bold", width: 110, textAlign: "end" }}
                >
                  Subtotal
                </Typography>
                <Typography
                  style={{
                    fontWeight: "normal",
                    width: 110,
                    textAlign: "start",
                  }}
                >
                  {totalSubtotal} {selectedCurrency?.currencySymbol || company.currencySymbol}
                </Typography>
              </Space>

              <Space>
                <Typography
                  style={{ fontWeight: "bold", width: 110, textAlign: "end" }}
                >
                  Discount
                </Typography>
                <Typography
                  style={{
                    fontWeight: "normal",
                    width: 110,
                    textAlign: "start",
                  }}
                >
                  {totalDiscount} {selectedCurrency?.currencySymbol || company.currencySymbol}
                </Typography>
              </Space>

              <Space>
                <Typography
                  style={{ fontWeight: "bold", width: 110, textAlign: "end" }}
                >
                  Tax
                </Typography>
                <Typography
                  style={{
                    fontWeight: "normal",
                    width: 110,
                    textAlign: "start",
                  }}
                >
                  {totalTax} {selectedCurrency?.currencySymbol || company.currencySymbol}
                </Typography>
              </Space>

              <Space>
                <Typography
                  style={{ fontWeight: "bold", width: 110, textAlign: "end" }}
                >
                  Shipping
                </Typography>
                <Typography
                  style={{
                    fontWeight: "normal",
                    width: 110,
                    textAlign: "start",
                  }}
                >
                  {shippingAmount ? shippingAmount : 0} {selectedCurrency?.currencySymbol || company.currencySymbol}
                </Typography>
              </Space>

              <Space wrap>
                <Typography style={{ fontWeight: "bold" }}>Total</Typography>
                <Typography
                  style={{
                    fontWeight: "normal",
                    minWidth: 110,
                    textAlign: "start",
                  }}
                >
                  {total} {selectedCurrency?.currencySymbol || company.currencySymbol}
                </Typography>
              </Space>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreatePurchaseOrderDrawer;
