import {
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  InputNumber,
  DatePicker,
  Select,
} from "antd";
import {
  useAccountingServiceUpdateTransaction,
  useAccountingServiceGetTransactionsKey,
} from "api/queries";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import AccountSelect from "components/common/AccountSelect";

interface IEditTransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
  defaultValues: any; // This holds the transaction's default values for editing
}

const EditTransactionModal: React.FC<IEditTransactionModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  defaultValues,
}) => {
  const [form] = Form.useForm();

  // Hook to call the mutation for updating a transaction
  const { mutate, isPending } = useAccountingServiceUpdateTransaction({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetTransactionsKey],
      });
      form.resetFields();
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.message || err || "Failed to update transaction");
    },
  });

  // Handle form submission
  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
      idTransaction: defaultValues.idTransaction, // Pass the ID of the transaction to be updated
    });
  };

  // Populate form with default values when the modal opens
  useEffect(() => {
    form.setFieldsValue({
      ...defaultValues,
      transactionDate: dayjs(defaultValues?.transactionDate) || dayjs(),
      debitAccount: defaultValues?.debitAccount?.idAccount || 0,
      creditAccount: defaultValues?.creditAccount?.idAccount || 0,
    });
  }, [form, defaultValues, isOpen]);

  return (
    <Modal
      title="Edit Transaction"
      open={isOpen}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={form.submit}
      confirmLoading={isPending}
      okText="Update"
      cancelText="Cancel"
      destroyOnClose
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        name="edit-transaction-form"
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="transactionDescription"
              label="Description"
              rules={[
                { required: true, message: "Please enter a description" },
              ]}
            >
              <Input placeholder="Enter transaction description" />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                { required: true, message: "Please enter a valid amount" },
              ]}
            >
              <InputNumber
                min={0.01}
                placeholder="Enter amount"
                style={{ width: "100%" }}
                step="0.01"
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="transactionDate"
              label="Date"
              rules={[{ required: true, message: "Please select a date" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="debitAccount"
              label="Debit Account"
              rules={[
                { required: true, message: "Please select a debit account" },
              ]}
            >
              <AccountSelect
                isForm={true}
                selectProps={{
                  showSearch: true,
                  allowClear: true,
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="creditAccount"
              label="Credit Account"
              rules={[
                { required: true, message: "Please select a credit account" },
              ]}
            >
              <AccountSelect
                isForm={true}
                selectProps={{
                  showSearch: true,
                  allowClear: true,
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name="notes" label="Notes" rules={[{ required: false }]}>
              <Input.TextArea placeholder="Enter additional notes (optional)" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditTransactionModal;
