import { PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Col, Form, Input, Modal, notification, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useAccountingServiceCreateTransaction,
  useAccountingServiceGetAccounts,
  useAccountingServiceGetTransactionsKey,
} from "api/queries";
import queryString from "query-string";
import { FC, useEffect, useState } from "react";
import { TransactionType } from "utils/enums"; // Import the TransactionType enum

export const CreateTransactionModal: FC<any> = ({ isOpen, onClose }) => {
  const [formRef] = Form.useForm();
  const [selectedType, setSelectedType] = useState<string | null>(null);

  const qc = useQueryClient();

  const { data: accountsRes } = useAccountingServiceGetAccounts({
    sort: "",
    pagination: queryString.stringify({ limit: 100, offset: 0 }),
    filters: "",
  });

  const { mutate, isPending, error } = useAccountingServiceCreateTransaction(
    {}
  );

  const handleSubmit = (values: any) => {
    values.amount = +values.amount;
    values.transactionDate = new Date();
    mutate(
      {
        requestBody: values,
      },
      {
        onError: (error: any) => {
          notification.error({
            message: "Error creating transaction",
            description:
              error?.body?.message ||
              error?.response?.data?.message ||
              error?.message,
          });
        },
        onSuccess: (data) => {
          qc.invalidateQueries({
            queryKey: [useAccountingServiceGetTransactionsKey],
          });
          onClose(true);
        },
      }
    );
  };

  // Watch changes in the transactionType field to adjust form validation dynamically
  const transactionType = Form.useWatch("transactionType", formRef);

  useEffect(() => {
    setSelectedType(transactionType);
    formRef.validateFields(); // Trigger validation when the type changes
  }, [transactionType]);

  // Dynamic validation rules for debit and credit accounts based on transaction type
  const getDebitValidation = () => {
    switch (selectedType) {
      case TransactionType.PURCHASE:
      case TransactionType.EXPENSE:
      case TransactionType.PAYMENT:
      case TransactionType.ADJUSTMENT:
        return [
          {
            required: true,
            message: "Debit account is required for this transaction type",
          },
        ];
      case TransactionType.TRANSFER:
        return [
          {
            required: true,
            message: "Debit account is required for transfers",
          },
        ];
      case TransactionType.OPENING_BALANCE:
        return [];
      default:
        return [];
    }
  };

  const getCreditValidation = () => {
    switch (selectedType) {
      case TransactionType.SALE:
      case TransactionType.RECEIPT:
      case TransactionType.CREDIT_NOTE:
      case TransactionType.DEBIT_NOTE:
        return [
          {
            required: true,
            message: "Credit account is required for this transaction type",
          },
        ];
      case TransactionType.TRANSFER:
        return [
          {
            required: true,
            message: "Credit account is required for transfers",
          },
        ];
      case TransactionType.OPENING_BALANCE:
        return [];
      default:
        return [];
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      okText="Create"
      okButtonProps={{
        icon: <PlusOutlined />,
        loading: isPending,
      }}
      cancelButtonProps={{ disabled: isPending }}
      onOk={formRef.submit}
    >
      <Row>
        <h1>Create Transaction</h1>
      </Row>
      <Row>
        <Col xs={24}>
          <Form form={formRef} onFinish={handleSubmit} layout="vertical">
            <Row gutter={30}>
              {/* Debit Account */}
              <Col xs={24} md={12}>
                <Form.Item
                  hasFeedback
                  label="Debit"
                  name="debitAccount"
                  rules={getDebitValidation()}
                >
                  <Select
                    showSearch
                    options={accountsRes?.items.map((i) => ({
                      value: i.idAccount,
                      label: i.accountName,
                      key: i.idAccount + "account-select-debit",
                      options: [
                        {
                          value: i.idAccount,
                          label: i.accountName,
                          key: i.idAccount + i.accountName,
                        },
                        ...i.childAccounts.map((c) => ({
                          value: c.idAccount,
                          label: c.accountName,
                          key: c.idAccount + "child" + i.accountName,
                        })),
                      ],
                    }))}
                    placeholder="Select Debit Account"
                    filterOption={(inputValue, option) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>

              {/* Credit Account */}
              <Col xs={24} md={12}>
                <Form.Item
                  hasFeedback
                  label="Credit"
                  name="creditAccount"
                  rules={getCreditValidation()}
                >
                  <Select
                    showSearch
                    options={accountsRes?.items.map((i) => ({
                      value: i.idAccount,
                      label: i.accountName,
                      key: i.idAccount + "account-select-credit",
                      options: [
                        {
                          value: i.idAccount,
                          label: i.accountName,
                          key: i.idAccount + i.accountName,
                        },
                        ...i.childAccounts.map((c) => ({
                          value: c.idAccount,
                          label: c.accountName,
                          key: c.idAccount + "child" + i.accountName,
                        })),
                      ],
                    }))}
                    placeholder="Select Credit Account"
                    filterOption={(inputValue, option) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>

              {/* Transaction Type */}
              <Col xs={24} md={12}>
                <Form.Item
                  hasFeedback
                  label="Transaction type"
                  name="transactionType"
                  rules={[
                    {
                      required: true,
                      message: "Transaction type must be selected",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    options={Object.keys(TransactionType).map((key) => ({
                      value:
                        TransactionType[key as keyof typeof TransactionType],
                      label:
                        TransactionType[key as keyof typeof TransactionType],
                      key: key,
                    }))}
                    placeholder="Select Transaction Type"
                    filterOption={(inputValue, option) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>

              {/* Amount */}
              <Col xs={24} md={12}>
                <Form.Item
                  hasFeedback
                  label="Amount"
                  name="amount"
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: true,
                      message: "Amount must be entered",
                    },
                    {
                      min: 0.01,
                      message: "Amount must be at least 0.01",
                    },
                  ]}
                >
                  <Input name="amount" type="number" />
                </Form.Item>
              </Col>

              {/* Description */}
              <Col xs={24}>
                <Form.Item
                  hasFeedback
                  label="Description"
                  name="transactionDescription"
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: true,
                      message: "Description must be entered",
                    },
                  ]}
                >
                  <TextArea name="description" rows={2} />
                </Form.Item>
              </Col>

              {/* Notes */}
              <Col xs={24}>
                <Form.Item
                  hasFeedback
                  label="Note"
                  name="note"
                  validateTrigger="onChange"
                >
                  <TextArea name="note" rows={2} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
