import {
  Button,
  Col,
  Divider,
  Flex,
  message,
  notification,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { CreateTransactionModal } from "./CreateTransactionModal";
import {
  useAccountingServiceDeleteTransaction,
  useAccountingServiceGetTransactions,
  useAccountingServiceGetTransactionsKey,
} from "api/queries";
import { useEffect, useState } from "react";
import * as qs from "qs";
import queryString from "query-string";
import { TableActions } from "components/common";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import useFilters, {
  FilterTemplate,
  FilterType,
  IFilterItem,
} from "hooks/UseFilter";
import EditTransactionModal from "./EditTransactionModal";

const filtersDef: IFilterItem[] = [
  {
    key: "transactionNumber",
    label: "Transaction Number",
    type: FilterType.Text,
  },
  {
    key: "transactionDate",
    label: "Transaction Date",
    type: FilterType.DateRange,
  },
  {
    key: "debitAccount",
    label: "Debit Account",
    type: FilterType.template,
    template: FilterTemplate.AccountSelect,
  },
  {
    key: "creditAccount",
    label: "Credit Account",
    type: FilterType.template,
    template: FilterTemplate.AccountSelect,
  },
];

export default function TransactionListing() {
  const [createTransactionModalOpen, setCreateTransactionModalOpen] =
    useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [editTransactionModalOpen, setEditTransactionModalOpen] =
    useState(false);

  const company = useSelector((state: any) => state.company);
  const queryClient = useQueryClient();

  const RenderAmount = (amount: number, record: any) => {
    return `${amount * record.exchangeRateAmount} ${
      record?.currency?.currencySymbol || company.currencySymbol
    }`;
  };

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetTransactions(
      {
        filters: qs.stringify(filtersQuery),
        pagination: queryString.stringify({
          limit,
          offset: (page - 1) * limit,
        }),
        sort: sortQuery,
      },
      [useAccountingServiceGetTransactionsKey]
    );

  const { mutate: deleteTransactionMutation, isPending: isDeletePending } =
    useAccountingServiceDeleteTransaction({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [useAccountingServiceGetTransactionsKey],
        });
        message.success("Transaction deleted successfully");
      },
      onError: (err: any) => {
        notification.error({
          message: "Error deleting transaction",
          description:
            err?.body?.message || err?.response?.data?.message || err?.message,
          type: "error",
        });
      },
    });

  const handleEditTransactionModalClose = (refresh?: boolean) => {
    setEditTransactionModalOpen(false);
  };

  const handleEditTransactionModalOpen = (transaction: any) => {
    setEditTransactionModalOpen(true);
    setSelectedTransaction(transaction);
  };

  const handleDeleteTransaction = async (id: number) => {
    await deleteTransactionMutation({
      idTransaction: id,
    });
    refetch();
  };

  const columns = [
    {
      title: "Transaction Number",
      dataIndex: "transactionNumber",
      key: "transactionNumber",
      width: "auto",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      width: "auto",
      render: RenderAmount,
    },
    {
      title: "Debit",
      dataIndex: "debitAccount",
      key: "debitAccount",
      sorter: true,
      width: "auto",
      render: (account: any) => account?.accountName,
    },
    {
      title: "Credit",
      dataIndex: "creditAccount",
      key: "creditAccount",
      sorter: true,
      width: "auto",
      render: (account: any) => account?.accountName,
    },
    {
      title: "Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      sorter: true,
      width: "auto",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
    },
    {
      title: "Description",
      dataIndex: "transactionDescription",
      key: "transactionDescription",
      sorter: true,
      width: "auto",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_i: any, row: any) => (
        <TableActions
          onEdit={() => handleEditTransactionModalOpen(row)}
          onDeletePopConfirm={() => handleDeleteTransaction(row.idTransaction)}
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This transaction will be deleted permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
      width: "auto",
    },
  ];

  const handleCreateTransactionModalClose = (refresh?: boolean) => {
    setCreateTransactionModalOpen(false);
  };

  const handleCreateTransactionModalOpen = () => {
    setCreateTransactionModalOpen(true);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    refetch();
  }, [limit, page, sortQuery, filtersQuery]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Typography.Title>Transactions</Typography.Title>
        </Col>
        <Col xs={12}>
          <Flex justify="end" align="center">
            <Space>
              {FilterButton}
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                onClick={handleCreateTransactionModalOpen}
              >
                Create
              </Button>
            </Space>
          </Flex>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            dataSource={data?.items || []}
            columns={columns}
            loading={isLoading || isRefetching}
            style={{ minHeight: "300px", height: "30%" }}
            scroll={{ x: "max-content" }}
            onChange={handleTableChange}
            pagination={{
              total: data?.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
          />
        </Col>
      </Row>

      <CreateTransactionModal
        isOpen={createTransactionModalOpen}
        onClose={handleCreateTransactionModalClose}
      />

      <EditTransactionModal
        isOpen={editTransactionModalOpen}
        onClose={handleEditTransactionModalClose}
        queryClient={queryClient}
        defaultValues={selectedTransaction}
      />
    </>
  );
}
