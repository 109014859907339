import { Tag } from "antd";

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  SENT = "SENT",
  PAID = "PAID",
  PARTIAL = "PARTIAL",
  UNPAID = "UNPAID",
  OVER_DUE = "OVER_DUE",
  CANCELED = "CANCELED",
}

export default function InvoiceStatusComponent({ status }: { status: InvoiceStatus | undefined }) {
  switch (status) {
    case InvoiceStatus.DRAFT:
      return <Tag color="orange">Draft</Tag>;
    case InvoiceStatus.SENT:
      return <Tag color="green">Sent</Tag>;
    case InvoiceStatus.PARTIAL:
      return <Tag color="gold">Partially Paid</Tag>;
    case InvoiceStatus.PAID:
      return <Tag color="success">Paid</Tag>;
    case InvoiceStatus.OVER_DUE:
      return <Tag color="red">Over Due</Tag>;
    case InvoiceStatus.UNPAID:
      return <Tag color="gold">Unpaid</Tag>;
    case InvoiceStatus.CANCELED:
      return <Tag color="red">Canceled</Tag>;
    default:
      return <Tag color="grey">Unknown</Tag>;
  }
}