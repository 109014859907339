import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import {
  useAccountingServiceUpdateTax,
  useAccountingServiceGetTaxesKey,
} from "api/queries";
import { $TaxType } from "api/requests";
import React, { useEffect } from "react";

interface IEditTaxModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
  defaultValues: any; // This will hold the default tax values for editing
}

const EditTaxModal: React.FC<IEditTaxModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  defaultValues,
}) => {
  const [form] = Form.useForm();

  // Hook to call the mutation for updating a tax
  const { mutate, isPending } = useAccountingServiceUpdateTax({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetTaxesKey],
      });
      form.resetFields();
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.message || err || "Failed to update tax");
    },
  });

  // Function to handle the form submission
  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
      idTax: defaultValues.idTax, // Use the default tax ID for the update
    });
  };

  // UseEffect to populate the form fields with default values when the modal opens
  useEffect(() => {
    form.setFieldsValue({
      ...defaultValues,
    });
  }, [form, defaultValues, isOpen]);

  return (
    <Modal
      title="Edit Tax"
      open={isOpen}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={form.submit}
      confirmLoading={isPending}
      okText="Update"
      cancelText="Cancel"
      destroyOnClose
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        name="edit-tax-form"
      >
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item name="taxName" rules={[{ required: true }]}>
              <Input placeholder="Tax Name" />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="taxRate"
              rules={[{ required: true }, { min: 0.01 }]}
            >
              <Input placeholder="Tax Rate" type="number" min="0" step="0.01" />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item name="taxType" rules={[{ required: true }]}>
              <Select
                placeholder="Tax Type"
                options={$TaxType.enum.map((type) => ({
                  label: type,
                  value: type,
                  key: type + "tax-select-tax-type",
                }))}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item name="taxDescription">
              <Input placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditTaxModal;
