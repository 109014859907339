import React from 'react';
import { List, Typography, Row, Col } from 'antd';
import {
  AppstoreOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  BarChartOutlined,
  FileOutlined,
  UserOutlined,
} from '@ant-design/icons';

const { Title, Text } = Typography;

// Define the report categories and their reports
const reportCategories = {
  inventory: {
    reports: [
      { title: 'Inventory Summary', description: 'Overview of inventory levels', icon: AppstoreOutlined },
      { title: 'Stock Movement', description: 'Track incoming and outgoing inventory', icon: ShoppingCartOutlined },
      { title: 'Inventory Valuation', description: 'Current value of stock on hand', icon: DollarCircleOutlined },
      { title: 'Reorder Levels', description: 'Reports on items that need to be reordered', icon: FileOutlined },
      { title: 'Inventory Aging', description: 'Age of inventory in stock', icon: BarChartOutlined },
    ],
  },
  accounting: {
    reports: [
      { title: 'Profit and Loss', description: 'Detailed Profit and Loss statement', icon: DollarCircleOutlined },
      { title: 'Balance Sheet', description: 'Overview of company assets and liabilities', icon: FileOutlined },
    ],
  },
  vendor: {
    reports: [
      { title: 'Vendor Summary', description: 'Overview of vendor purchases', icon: TeamOutlined },
      { title: 'Purchase Orders', description: 'Track vendor purchase orders', icon: ShoppingCartOutlined },
    ],
  },
  customer: {
    reports: [
      { title: 'Customer Overview', description: 'Detailed report on customer data', icon: UserOutlined },
      { title: 'Sales by Customer', description: 'Track sales per customer', icon: BarChartOutlined },
    ],
  },
};

const ReportDashboard = () => {
  return (
    <div style={{ padding: '40px' }}>
      <Title level={2} style={{ marginBottom: '20px' }}>Reports Overview</Title>

      {/* Inventory Reports Section */}
      <section style={{ marginBottom: '40px' }}>
        <Title level={3}>Inventory Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={reportCategories.inventory.reports}
          renderItem={report => (
            <List.Item style={{ padding: '10px 0', transition: 'background 0.3s', cursor: 'pointer' }}
              onMouseEnter={(e) => (e.currentTarget.style.background = '#f0f0f0')}
              onMouseLeave={(e) => (e.currentTarget.style.background = 'transparent')}>
              <List.Item.Meta
                avatar={<report.icon style={{ fontSize: '24px', color: '#1890ff' }} />}
                title={<Text style={{ fontSize: '16px' }}>{report.title}</Text>}
                description={<Text style={{ fontSize: '14px' }}>{report.description}</Text>}
              />
            </List.Item>
          )}
        />
      </section>

      {/* Accounting Reports Section */}
      <section style={{ marginBottom: '40px' }}>
        <Title level={3}>Accounting Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={reportCategories.accounting.reports}
          renderItem={report => (
            <List.Item style={{ padding: '10px 0', transition: 'background 0.3s', cursor: 'pointer' }}
              onMouseEnter={(e) => (e.currentTarget.style.background = '#f0f0f0')}
              onMouseLeave={(e) => (e.currentTarget.style.background = 'transparent')}>
              <List.Item.Meta
                avatar={<report.icon style={{ fontSize: '24px', color: '#52c41a' }} />}
                title={<Text style={{ fontSize: '16px' }}>{report.title}</Text>}
                description={<Text style={{ fontSize: '14px' }}>{report.description}</Text>}
              />
            </List.Item>
          )}
        />
      </section>

      {/* Vendor Reports Section */}
      <section style={{ marginBottom: '40px' }}>
        <Title level={3}>Vendor/Supplier Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={reportCategories.vendor.reports}
          renderItem={report => (
            <List.Item style={{ padding: '10px 0', transition: 'background 0.3s', cursor: 'pointer' }}
              onMouseEnter={(e) => (e.currentTarget.style.background = '#f0f0f0')}
              onMouseLeave={(e) => (e.currentTarget.style.background = 'transparent')}>
              <List.Item.Meta
                avatar={<report.icon style={{ fontSize: '24px', color: '#faad14' }} />}
                title={<Text style={{ fontSize: '16px' }}>{report.title}</Text>}
                description={<Text style={{ fontSize: '14px' }}>{report.description}</Text>}
              />
            </List.Item>
          )}
        />
      </section>

      {/* Customer Reports Section */}
      <section style={{ marginBottom: '40px' }}>
        <Title level={3}>Customer Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={reportCategories.customer.reports}
          renderItem={report => (
            <List.Item style={{ padding: '10px 0', transition: 'background 0.3s', cursor: 'pointer' }}
              onMouseEnter={(e) => (e.currentTarget.style.background = '#f0f0f0')}
              onMouseLeave={(e) => (e.currentTarget.style.background = 'transparent')}>
              <List.Item.Meta
                avatar={<report.icon style={{ fontSize: '24px', color: '#eb2f96' }} />}
                title={<Text style={{ fontSize: '16px' }}>{report.title}</Text>}
                description={<Text style={{ fontSize: '14px' }}>{report.description}</Text>}
              />
            </List.Item>
          )}
        />
      </section>
    </div>
  );
};

export default ReportDashboard;

/*
import React from 'react';
import { List, Typography } from 'antd';
import { motion } from 'framer-motion';
import {
  AppstoreOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  BarChartOutlined,
  FileOutlined,
  UserOutlined,
} from '@ant-design/icons';

const { Title, Text } = Typography;

// Define the report categories and their reports
const reportCategories = {
  inventory: {
    reports: [
      { title: 'Inventory Summary', description: 'Overview of inventory levels', icon: AppstoreOutlined },
      { title: 'Stock Movement', description: 'Track incoming and outgoing inventory', icon: ShoppingCartOutlined },
      { title: 'Inventory Valuation', description: 'Current value of stock on hand', icon: DollarCircleOutlined },
      { title: 'Reorder Levels', description: 'Reports on items that need to be reordered', icon: FileOutlined },
      { title: 'Inventory Aging', description: 'Age of inventory in stock', icon: BarChartOutlined },
    ],
  },
  accounting: {
    reports: [
      { title: 'Profit and Loss', description: 'Detailed Profit and Loss statement', icon: DollarCircleOutlined },
      { title: 'Balance Sheet', description: 'Overview of company assets and liabilities', icon: FileOutlined },
    ],
  },
  vendor: {
    reports: [
      { title: 'Vendor Summary', description: 'Overview of vendor purchases', icon: TeamOutlined },
      { title: 'Purchase Orders', description: 'Track vendor purchase orders', icon: ShoppingCartOutlined },
    ],
  },
  customer: {
    reports: [
      { title: 'Customer Overview', description: 'Detailed report on customer data', icon: UserOutlined },
      { title: 'Sales by Customer', description: 'Track sales per customer', icon: BarChartOutlined },
    ],
  },
};

const ReportDashboard = () => {
  return (
    <div style={{ padding: '40px' }}>
      <Title level={2} style={{ marginBottom: '20px', textAlign: 'center', color: '#f79f24' }}>
        Happy Mode Reports Overview 🎉
      </Title>

      <section style={{ marginBottom: '40px' }}>
        <Title level={3} style={{ textAlign: 'center' }}>Inventory Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={reportCategories.inventory.reports}
          renderItem={report => (
            <motion.div
              whileHover={{ scale: 1.05, backgroundColor: '#f6f8fa', transition: { duration: 0.3 } }}
              whileTap={{ scale: 0.95 }}
              style={{
                borderRadius: '10px',
                padding: '10px 20px',
                marginBottom: '10px',
                cursor: 'pointer',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              <List.Item>
                <List.Item.Meta
                  avatar={<motion.div
                    whileHover={{ rotate: 360, transition: { duration: 0.5 } }}
                    style={{ fontSize: '24px', color: '#1890ff' }}>
                    <report.icon />
                  </motion.div>}
                  title={<motion.div whileHover={{ color: '#f79f24' }}><Text style={{ fontSize: '16px' }}>{report.title}</Text></motion.div>}
                  description={<Text style={{ fontSize: '14px', color: '#595959' }}>{report.description}</Text>}
                />
              </List.Item>
            </motion.div>
          )}
        />
      </section>

      <section style={{ marginBottom: '40px' }}>
        <Title level={3} style={{ textAlign: 'center' }}>Accounting Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={reportCategories.accounting.reports}
          renderItem={report => (
            <motion.div
              whileHover={{ scale: 1.05, backgroundColor: '#f6f8fa', transition: { duration: 0.3 } }}
              whileTap={{ scale: 0.95 }}
              style={{
                borderRadius: '10px',
                padding: '10px 20px',
                marginBottom: '10px',
                cursor: 'pointer',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              <List.Item>
                <List.Item.Meta
                  avatar={<motion.div
                    whileHover={{ rotate: 360, transition: { duration: 0.5 } }}
                    style={{ fontSize: '24px', color: '#52c41a' }}>
                    <report.icon />
                  </motion.div>}
                  title={<motion.div whileHover={{ color: '#52c41a' }}><Text style={{ fontSize: '16px' }}>{report.title}</Text></motion.div>}
                  description={<Text style={{ fontSize: '14px', color: '#595959' }}>{report.description}</Text>}
                />
              </List.Item>
            </motion.div>
          )}
        />
      </section>

      <section style={{ marginBottom: '40px' }}>
        <Title level={3} style={{ textAlign: 'center' }}>Vendor/Supplier Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={reportCategories.vendor.reports}
          renderItem={report => (
            <motion.div
              whileHover={{ scale: 1.05, backgroundColor: '#f6f8fa', transition: { duration: 0.3 } }}
              whileTap={{ scale: 0.95 }}
              style={{
                borderRadius: '10px',
                padding: '10px 20px',
                marginBottom: '10px',
                cursor: 'pointer',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              <List.Item>
                <List.Item.Meta
                  avatar={<motion.div
                    whileHover={{ rotate: 360, transition: { duration: 0.5 } }}
                    style={{ fontSize: '24px', color: '#faad14' }}>
                    <report.icon />
                  </motion.div>}
                  title={<motion.div whileHover={{ color: '#faad14' }}><Text style={{ fontSize: '16px' }}>{report.title}</Text></motion.div>}
                  description={<Text style={{ fontSize: '14px', color: '#595959' }}>{report.description}</Text>}
                />
              </List.Item>
            </motion.div>
          )}
        />
      </section>

      <section style={{ marginBottom: '40px' }}>
        <Title level={3} style={{ textAlign: 'center' }}>Customer Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={reportCategories.customer.reports}
          renderItem={report => (
            <motion.div
              whileHover={{ scale: 1.05, backgroundColor: '#f6f8fa', transition: { duration: 0.3 } }}
              whileTap={{ scale: 0.95 }}
              style={{
                borderRadius: '10px',
                padding: '10px 20px',
                marginBottom: '10px',
                cursor: 'pointer',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              <List.Item>
                <List.Item.Meta
                  avatar={<motion.div
                    whileHover={{ rotate: 360, transition: { duration: 0.5 } }}
                    style={{ fontSize: '24px', color: '#eb2f96' }}>
                    <report.icon />
                  </motion.div>}
                  title={<motion.div whileHover={{ color: '#eb2f96' }}><Text style={{ fontSize: '16px' }}>{report.title}</Text></motion.div>}
                  description={<Text style={{ fontSize: '14px', color: '#595959' }}>{report.description}</Text>}
                />
              </List.Item>
            </motion.div>
          )}
        />
      </section>
    </div>
  );
};

export default ReportDashboard;
*/